<template>
  <div class="stack">
    <div class="head">
      <form class="search-form">
        <b-form-input
          v-model="searchText"
          type="text"
          placeholder="Search"
          required
        ></b-form-input>

        <CIcon name="search" size="custom" />
      </form>

      <button class="bi-button" @click="onCreateScript">Create script</button>
    </div>
    <div>
      <b-tabs content-class="mt-3">
        <b-tab title="All" active><p>I'm the first tab</p></b-tab>
        <b-tab title="Tag"><p>I'm the second tab</p></b-tab>
        <b-tab title="Historical data"><p>I'm the second tab</p></b-tab>
      </b-tabs>
    </div>
    <div class="stack">
      <ScriptListItem @click="showRunScript = true" />
      <ScriptListItem />
      <ScriptListItem />
    </div>

    <CreateScript
      :is-open="showCreateScript"
      @close="showCreateScript = false"
    />
    <RunScript :is-open="showRunScript" @close="showRunScript = false" />
  </div>
</template>
<script>
import ScriptListItem from "./components/ScriptListItem.vue";
import CreateScript from "./components/CreateScript.vue";
import RunScript from "./components/RunScript.vue";
export default {
  name: "ScriptMarketplace",
  components: { ScriptListItem, CreateScript, RunScript },
  data() {
    return {
      showCreateScript: false,
      showRunScript: false,
      searchText: "",
    };
  },
  created() {},
  methods: {
    onCreateScript() {
      this.showCreateScript = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.stack {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-form {
  width: 500px;
  height: 44px;
  position: relative;

  input {
    height: 100%;
    border-radius: 10px;
    font-size: 16px;
  }

  .c-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 12px;
    top: 12px;
  }
}

::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: inherit;
  border: none;
  border-bottom: 2px solid #3e6bbd;
  color: #3e6bbd;
}

::v-deep .nav-tabs a.nav-link {
  color: inherit;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  border: none;
}
</style>
