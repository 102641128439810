<template>
  <vue-monaco-editor
    v-model:value="code"
    :options="MONACO_EDITOR_OPTIONS"
    @mount="handleMount"
    className="monaco-editor"
  />
</template>

<script setup>
import { ref, shallowRef } from "vue";

const MONACO_EDITOR_OPTIONS = {
  automaticLayout: true,
  formatOnType: true,
  formatOnPaste: true,
};

const code = ref("// some code...");
const editorRef = shallowRef();
const handleMount = (editor) => (editorRef.value = editor);
</script>
<style lang="scss">
.monaco-editor {
  background: #f8f9fc;
}
</style>
