<template>
  <CModal
    :show.sync="isOpen"
    :closeOnBackdrop="false"
    @blur="closeModal"
    @close="closeModal"
    @show="closeModal"
    width="60%"
    height="90%"
    alignment="center"
  >
    <template slot="header">
      <h4>Create a new script</h4>
      <svg
        @click="closeModal"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="currentColor"
        class="bi bi-x"
        viewBox="0 0 16 16"
      >
        <path
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
        />
      </svg>
    </template>
    <div class="create-script">
      <div class="create-script__editor">
        <MonacoCodeEditor />
      </div>

      <div class="create-script__right">
        <div class="stack">
          <div>
            <div class="label">Language:</div>
            <b-form-select v-model="language">
              <b-form-select-option v-for="op in languages" :value="op.id">{{
                op.name
              }}</b-form-select-option>
            </b-form-select>
          </div>

          <div>
            <div class="label">Name:</div>
            <b-form-input v-model="name" placeholder="Name"></b-form-input>
          </div>

          <div>
            <div class="label">Description:</div>
            <b-form-textarea
              v-model="description"
              placeholder="Description"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </div>

          <div>
            <div class="label">Tags:</div>
            <v-select
              placeholder="Tag"
              :options="tags"
              label="Tag"
              v-model="tag"
              taggable
              multiple
            />
          </div>

          <div class="grid">
            <div>
              <div class="label">Name:</div>
              <b-form-input v-model="name" placeholder="Name"></b-form-input>
            </div>
            <div>
              <div class="label">Client:</div>
              <b-form-select v-model="client">
                <b-form-select-option
                  v-for="op in items.clients"
                  :value="op.id"
                  >{{ op.name }}</b-form-select-option
                >
              </b-form-select>
              <!-- <v-select
                placeholder="Select client"
                :options="items.clients"
                :reduce="(temp) => temp.id"
                label="name"
                v-model="client"
              /> -->
            </div>
            <div>
              <div class="label">Project:</div>
              <b-form-select v-model="project">
                <b-form-select-option
                  v-for="op in items.projects"
                  :value="op.id"
                  >{{ op.name }}</b-form-select-option
                >
              </b-form-select>
            </div>
          </div>

          <div class="add-content">
            <button class="btn-plus">
              <CIcon name="cilPlus" class="plus" />
            </button>

            <span>Add</span>
          </div>

          <div>
            <div class="label">Status:</div>
            <b-form-select v-model="status">
              <b-form-select-option v-for="op in statuses" :value="op.id">{{
                op.name
              }}</b-form-select-option>
            </b-form-select>
          </div>
        </div>

        <div class="flex">
          <b-button class="btn mt-3 cus-button cuz-button--white">
            Run code
          </b-button>
          <b-button class="btn cus-button mt-3"> Save </b-button>
        </div>
      </div>
    </div>
    <template slot="footer-wrapper">{{ null }}</template>
  </CModal>
</template>
<script>
import "vue-select/dist/vue-select.css";
import MonacoCodeEditor from "./MonacoCodeEditor.vue";
import { ListsService } from "@/services/lists";
const languages = [
  {
    name: "Python",
    key: "python",
  },
];
export default {
  name: "CreateScript",
  components: { MonacoCodeEditor },
  props: {
    isOpen: Boolean,
  },
  data() {
    return {
      language: "python",
      languages,
      name: null,
      description: null,
      tags: [],
      tag: null,
      project: null,
      client: null,
      clients: [],
      projects: [],
      statuses: [],
      status: null,
      items: {
        clients: ["No options"],
        projects: ["No options"],
      },
    };
  },
  created() {
    this.fillDropdown("projects");
    this.fillDropdown("clients");
  },
  methods: {
    onLanguage() {},
    closeModal() {
      this.$emit("close");
    },
    async fillDropdown(collection_name) {
      var { data, res } = await ListsService.get_list({
        collection: collection_name,
        filter_off: false,
      });
      if (res.status !== 200) {
        return alert("Please check your internet connetion or log in");
      }
      if (data.success) {
        this.items[collection_name] = data.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep(.modal-header) {
  padding: 23px 30px;

  h4 {
    margin: 0;
  }
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}
.flex {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.stack {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.create-script {
  display: grid;
  grid-template-columns: 2fr 1fr;
  height: 100%;
  gap: 15px;
  &__right {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: space-between;

    .label {
      color: #0c2044;
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 6px;
    }
  }
}

.btn-plus {
  width: 30px;
  height: 30px;
  background: #e7edfb;
  border: none;
  border-radius: 50%;
  outline: none;

  .plus {
    color: #3e6bbd;
  }
}

.add-content {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #3e6bbd;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
</style>
