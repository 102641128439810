<template>
  <CModal
    :show.sync="showModal"
    @blur="closeModal"
    @close="closeModal"
    @show="closeModal"
    width="60%"
    alignment="center"
  >
    <template slot="header">
      <h4 class="modal-heading">
        Script for testing investment funds (Belgium)
      </h4>
      <!-- <svg
        @click="closeModal"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="currentColor"
        class="bi bi-x"
        viewBox="0 0 16 16"
      >
        <path
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
        />
      </svg> -->
    </template>
    <div class="run-script">
      <div class="script-l-item__head">
        <h3>Script for testing investment funds (Belgium)</h3>
        <!-- <img src="@/assets/images/Group 212.png" /> -->
      </div>
      <div class="script-l-item__row">
        <strong>Description: </strong>
        <span
          >Running custom-developed Python scripts for testing data of
          investment funds as part of audit process by auditors.</span
        >
      </div>
      <div class="script-l-item__row">
        <strong>Author: </strong>
        <span>developer1@mazars.be</span>
      </div>
      <div class="script-l-item__row">
        <strong>Tags: </strong>
        <span>Tag1, Tag2</span>
      </div>
      <div class="script-l-item__aligning">
        <div class="script-l-item__align">
          <div>Table 1</div>
          <button class="btn btn-align">To align</button>
        </div>
        <div class="script-l-item__align">
          <div>Table 1</div>
          <button class="btn btn-align">To align</button>
        </div>
      </div>
      <div class="bottom-script">
        <b-button
          class="btn mt-3 cus-button cuz-button--white"
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button class="btn cus-button mt-3"> Run the script </b-button>
      </div>
    </div>
    <template slot="footer-wrapper">{{ null }}</template>
  </CModal>
</template>
<script>
import ScriptListItem from "./ScriptListItem.vue";

export default {
  name: "RunScript",
  components: { ScriptListItem },
  props: { isOpen: Boolean },
  data() {
    return {
      showModal: false,
    };
  },
  watch: {
    isOpen: {
      immediate: true,
      handler() {
        this.showModal = this.isOpen;
      },
    },
    showModal: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.closeModal();
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss"></style>
