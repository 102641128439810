<template>
  <div class="script-l-item">
    <div class="script-l-item__head">
      <h3>Script for testing investment funds (Belgium)</h3>
      <img src="@/assets/images/Group 212.png" />
    </div>
    <div class="script-l-item__row">
      <strong>Description: </strong>
      <span
        >Running custom-developed Python scripts for testing data of investment
        funds as part of audit process by auditors.</span
      >
    </div>
    <div class="script-l-item__row">
      <strong>Author: </strong>
      <span>developer1@mazars.be</span>
    </div>
    <div class="script-l-item__row">
      <strong>Inputs: </strong>
      <span>3 DB tables</span>
    </div>
    <button class="get-it" @click="$emit('click')">Get it now</button>
  </div>
</template>
<script>
export default {
  name: "ScriptListItem",
};
</script>
