import { request } from "@/utils/request";
import { BASE_URL } from "@/constants/config";
import { ActiveUser } from "@/services/user";

export const LISTS_ENDPOINTS = {
  get_list: `${BASE_URL}api/lists/get_list`,
  get_list_template: `${BASE_URL}api/lists/get_list_template`,
  get_list_history: `${BASE_URL}api/lists/get_list_history`,
  record_item: `${BASE_URL}api/lists/record_item`,
  get_count: `${BASE_URL}api/lists/get_count`,
  update_entry: `${BASE_URL}api/lists/update_entry`,
  delete_data: `${BASE_URL}api/lists/delete_data`,
};

class Lists {
  async get_list({ collection, filter_off }) {
    var { data, res } = await request({
      url: LISTS_ENDPOINTS.get_list,
      method: "POST",
      skipRedirect: true,
      body: {
        collection,
        filter_off,
        email: ActiveUser.get()?.email.toLowerCase(),
      },
    });
    return {
      data,
      res,
    };
  }

  async get_list_template({ page, limit, filter }) {
    var { data, res } = await request({
      url: LISTS_ENDPOINTS.get_list_template,
      method: "POST",
      skipRedirect: true,
      body: {
        page,
        limit,
        filter,
      },
    });
    return {
      data,
      res,
    };
  }

  async get_list_history({ page, limit, filter }) {
    var { data, res } = await request({
      url: LISTS_ENDPOINTS.get_list_history,
      method: "POST",
      skipRedirect: true,
      body: {
        page,
        limit,
        filter,
      },
    });
    return {
      data,
      res,
    };
  }

  async get_count({ collection }) {
    var { data, res } = await request({
      url: LISTS_ENDPOINTS.get_count,
      method: "POST",
      skipRedirect: true,
      body: {
        collection,
        email: ActiveUser.get()?.email.toLowerCase(),
      },
    });
    return {
      data,
      res,
    };
  }

  async record_item({ collection, recordData }) {
    var { data, res } = await request({
      url: LISTS_ENDPOINTS.record_item,
      method: "POST",
      skipRedirect: true,
      body: {
        collection,
        recordData,
      },
    });
    return {
      data,
      res,
    };
  }

  async update_entry({ status, edited }) {
    var { data, res } = await request({
      url: LISTS_ENDPOINTS.update_entry,
      method: "POST",
      skipRedirect: true,
      body: {
        status,
        edited,
      },
    });
    return {
      data,
      res,
    };
  }

  async delete_data({ client, project, upload_id }) {
    var { data, res } = await request({
      url: LISTS_ENDPOINTS.delete_data,
      method: "POST",
      skipRedirect: true,
      body: {
        client,
        project,
        upload_id,
      },
    });
    return {
      data,
      res,
    };
  }
}

export const ListsService = new Lists();
